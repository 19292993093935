<template>
  <div class="country-select">
    <p>Seleccionar Pais:</p>
    <v-select
      id="type"
      :options="countryOptions"
      label="text"
      :clearable="false"
      multiple
      @input="setCountries"
      v-model="countriesSelect"
      :disabled="disabled"
    >
      <span slot="no-options">Nada que mostrar.</span>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { sortAlphabetize } from '@/utils/utils.js'
  
export default {
  components: {
    vSelect
  },
  props : {
    disabled: {
      type: Boolean,
      required: false
    },
    defaultSelectedCountry: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      //default value
      countriesSelect: [],
      countryOptions: this.getCountries()
    }
  },
  methods: {
    getCountries () {
      const userInfo = JSON.parse(localStorage.userInfo) 
      let countries = userInfo.authorized_countries.map((country) => ({
        ...country,
        value : country.alpha_2_code,
        text : country.name
      }))
      countries = sortAlphabetize(countries, 'text')
      countries.unshift({value : 'all', text : 'Todos'})

      if (this.defaultSelectedCountry) {
        this.setCountries([countries.find((country) => country.value === this.defaultSelectedCountry)])
      } else {
        this.setCountries([countries.find((country) => country.value === 'AR') || countries[1]])
      }


      return countries
    },
    setCountries (params) {
      this.countriesSelect = params
      this.existOptionAll()
      this.$emit('value', this.countriesSelect)
    },
    //If something other than "all" is selected, it's removed. If select "all" again, everything is deleted.
    existOptionAll () {
      const positionOfAll = this.countriesSelect.map(countrie => countrie.value).indexOf('all')
      if (positionOfAll === 0) this.countriesSelect.splice(positionOfAll, 1)
      if ((positionOfAll > 0) || (this.countriesSelect.length === 0)) this.countriesSelect = [{value : 'all', text : 'Todos'}]
    }
  },
  mounted() {
    //set the default country
    const defaultCountry = 
      this.defaultSelectedCountry ? 
        this.countryOptions.find(country => country.value === this.defaultSelectedCountry) 
        : this.countryOptions.find(country => country.value === 'AR') || this.countryOptions[1]

    //set country select
    this.countriesSelect = [defaultCountry]
  }
}
</script>
