var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "country-select" },
    [
      _c("p", [_vm._v("Seleccionar Pais:")]),
      _c(
        "v-select",
        {
          attrs: {
            id: "type",
            options: _vm.countryOptions,
            label: "text",
            clearable: false,
            multiple: "",
            disabled: _vm.disabled,
          },
          on: { input: _vm.setCountries },
          model: {
            value: _vm.countriesSelect,
            callback: function ($$v) {
              _vm.countriesSelect = $$v
            },
            expression: "countriesSelect",
          },
        },
        [
          _c("span", { attrs: { slot: "no-options" }, slot: "no-options" }, [
            _vm._v("Nada que mostrar."),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }